@mixin linear-gradient($direction, $color-stops...) {
  // Direction has been omitted and happens to be a color-stop
  @if is-direction($direction) == false {
    $color-stops: $direction, $color-stops;
    $direction: 180deg;
  }
  background: nth(nth($color-stops, 1), 1);
  background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
  background: linear-gradient($direction, $color-stops);
}

/*** Font Family  ***/
$font-body: 'Montserrat', sans-serif;;

/*** Color Palette ***/
$primary-color: #3e69f5; 
$secondary-color: #2E4057;
$white-color: #ffffff;

@mixin body-font { font-family: $font-body;  @include fluid-type($min_width, $max_width, 18px, 24px); font-weight:400; line-height:1.6; }

/*** Font-size as h1-h5 headings ***/
@mixin font-xl { font-size: 44px; line-height: 50px; font-weight: 500; }
@mixin font-lg { @include fluid-type($min_width, $max_width, 36px, 77px);line-height:1.2; font-weight: 600; }
@mixin font-md { @include fluid-type($min_width, $max_width, 26px, 34px); line-height: 36px; font-weight: 500; }
@mixin font-sm { @include fluid-type($min_width, $max_width, 21px, 40px); line-height:1; font-weight: 500; }
@mixin font-xs { font-size: 18px; line-height: 27px; font-weight: 500; }
@mixin font-xxs { font-size: 14px; line-height: 25px; font-weight: 500; }

@mixin border-radius($radius) { -webkit-border-radius: $radius; -moz-border-radius: $radius; -o-border-radius: $radius;	border-radius: $radius; }

@mixin opacity($opacity) { -webkit-opacity:$opacity; -moz-opacity:$opacity; -o-opacity:$opacity; -m-opacity:$opacity; opacity:$opacity; }

@mixin transition($seconds) { -webkit-transition: all $seconds ease; -moz-transition: all $seconds ease; -ms-transition: all $seconds ease; -o-transition: all $seconds ease; transition: all $seconds ease; }

@mixin trans-anim-none { -webkit-transition:none; -moz-transition:none; -o-transition:none; transition:none; }

@mixin space-none { margin:0; padding:0; }

@mixin app-none { -webkit-appearance: none; -moz-appearance: none; -o-appearance: none; -ms-appearance: none; appearance: none;}

@mixin point-none { -webkit-pointer-events:none; -moz-pointer-events:none; -o-pointer-events:none; -ms-pointer-events:none; pointer-events:none; }

/*** Background properties  ***/
@mixin bg-cmn { background-position:top center; background-repeat:no-repeat; background-size: cover; }
@mixin bg-parallax { background-attachment:fixed; @include bg-cmn; }
