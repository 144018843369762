.top-image {
  width: 100%;
  height: auto;
}
.map {
  width: 100%;
  height: auto;
  margin-top: -9%;
  position: absolute;
  z-index: -1;
}
.logo {
  max-width: 100%;
  height: auto;
  display: table;
  margin: 0px auto 34px;
}
.fitst-ces {
  position: relative;
}
.map-bg {
  position: absolute;
  z-index: -1;
  max-width: 100%;
  height: auto;
  left: 0;
  right: 0;
  margin: -7% auto 0;
}
.number {
  color: #ced2d1;
  @include fluid-type($min_width, $max_width, 80px, 170px);
  font-weight: 900;
  line-height: 0.7;
}
p {
  span {
    color: $primary-color;
  }
}
.devices {
  padding: 50px 0px;
  .app-phone {
    display: table;
    position: relative;
    @include media-breakpoint-down(sm) {
      margin: 0px auto;
    }
    .device1-shadow {
      position: absolute;
      top: -11%;
      left: -26%;
      height: 137%;
      max-width: 173%;
      z-index: 0;
    }
    .device2-shadow {
      position: absolute;
      top: 0%;
      left: -12%;
      height: 103%;
      max-width: 125%;
      z-index: 0;
    }
  }
  .device-description-col {
    background: #eaf5fa;
    box-shadow: 37px 9px 87px rgba(44, 54, 46, 0.13);
    @include media-breakpoint-down(sm) {
      box-shadow: 11px 20px 87px rgba(44, 54, 46, 0.13);
    }
    border-radius: 0px 31px 31px 0px;
    @include media-breakpoint-down(sm) {
      border-radius: 30px !important;
      margin: 0px 15px 45px;
    }
    z-index: -1;
    &::before {
      @include media-breakpoint-up(md) {
        content: "";
      }
      display: block;
      position: absolute;
      background: #eaf5fa;
      height: 100%;
      width: 40%;
      right: 100%;
      top: 0;
      z-index: -1;
    }
    @include media-breakpoint-down(sm) {
      &:after {
        content: "";
        width: 0px;
        height: 0px;
        border-left: 40px solid transparent;
        border-right: 40px solid transparent;
        border-top: 40px solid #eaf5fa;
        position: absolute;
        left: 50%;
        top: 100%;
        margin-left: -40px;
        z-index: 2;
      }
    }
    .device-description {
      margin: 120px 0;
      position: relative;
      @include media-breakpoint-down(lg) {
        margin: 70px 0;
      }
      @include media-breakpoint-down(sm) {
        margin: 0px;
        text-align: center;
        padding: 40px 15px;
      }
      &:before {
        @include media-breakpoint-up(md) {
          content: "";
        }
        height: 63%;
        width: 2px;
        background-color: #c7ddef;
        position: absolute;
        left: -20px;
        bottom: 0;
      }
      h4 {
        span {
          color: $primary-color;
          display: block;
        }
      }
      .translate-icon {
        max-width: 36%;
        height: auto;
        margin-top: 10%;
        @include media-breakpoint-down(sm) {
          margin: 0px 0 -6% -7%;
          max-width: 100px;
        }
      }
      .japanese {
        position: relative;
        display: table;
        @include media-breakpoint-down(md) {
          margin-top: 60px;
        }
        @include media-breakpoint-down(sm) {
          margin: 60px auto 0;
        }
        .cust-tooltip {
          position: absolute;
          bottom: calc(100% + 7px);
          right: -160px;
          @include media-breakpoint-down(md) {
            right: 0;
          }
        }
        span {
          @include fluid-type($min_width, $max_width, 14px, 22px);
          font-weight: 600;
          background-color: #94bfee;
          line-height: 1.6;
          display: inline-block;
          .select-start {
            margin: -7px 0 0 -7px;
          }
          .select-end {
            margin: 0 -7px -4px 0;
          }
        }
        &:after {
          content: "";
          width: 0px;
          height: 0px;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          border-top: 8px solid #343435;
          position: absolute;
          bottom: 100%;
          right: 10%;
        }
      }
    }
  }
  &:nth-child(odd) {
    .row {
      flex-direction: row-reverse;
    }
  }
  &:nth-child(even) {
    .device-col {
      direction: rtl;
    }
    .device-description-col {
      border-radius: 31px 0px 0px 31px;
      box-shadow: -20px 9px 87px rgba(44, 54, 46, 0.13);
      @include media-breakpoint-down(sm) {
        box-shadow: 11px 20px 87px rgba(44, 54, 46, 0.13);
      }
      &::before {
        left: 100%;
      }
      .device-description {
        &::before {
          display: none;
        }
      }
    }
  }
}

.pink-sec {
  color: $white-color;
  background: url(../img/pink-bg.png) no-repeat center center;
  background-size: cover;
  padding: 230px 0px;
  @include media-breakpoint-down(lg) {
    padding: 90px 0px 150px;
  }
  .fleft-col {
    text-align: right;
    @include media-breakpoint-down(lg) {
      text-align: left;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  h4 {
    @include fluid-type($min_width, $max_width, 36px, 80px);
    font-weight: 100;
    @include media-breakpoint-down(sm) {
      text-align: center;
    }
  }
  h5 {
    @include fluid-type($min_width, $max_width, 28px, 38px);
    font-weight: 500;
    line-height: 1.1;
    @include media-breakpoint-down(sm) {
      text-align: center;
    }
  }
  .templates {
    position: relative;
    display: table;
    margin-left: -17%;
    @include media-breakpoint-down(lg) {
      margin: 0px auto;
    }
    .template {
      @include media-breakpoint-up(md) {
        max-width: 120%;
      }
    }
  }
  .number {
    color: $white-color;
    opacity: 0.28;
    margin-bottom: 60px;
    display: block;
  }
  .return1,
  .return2,
  .return3 {
    max-width: 100%;
    width: 270px;
    height: auto;
    @include media-breakpoint-down(lg) {
      max-width: 200px;
    }
  }
  .return1 {
    position: absolute;
    bottom: 22px;
    right: 0;
    margin-right: -100px;
    @include media-breakpoint-down(lg) {
      margin-right: -50px;
    }
    @include media-breakpoint-down(md) {
      bottom: 15%;
      margin-right: 40px;
    }
  }
  .return2 {
    margin-top: -80px;
    @include media-breakpoint-down(md) {
      display: table;
      margin: 0px 0 0 auto;
    }
    @include media-breakpoint-down(sm) {
      margin: 24px auto;
    }
  }
  svg {
    max-width: 100%;
    height: auto;
  }
}
.sec3 {
  text-align: center;
  h2 {
    margin-bottom: 30px;
    &:after {
      content: "";
      background: #afc2f8;
      height: 7px;
      width: 55px;
      border-radius: 10px;
      display: table;
      margin: 30px auto;
    }
  }
  h3 {
    margin-bottom: 40px;
    span {
      color: $primary-color;
    }
  }
  .custmize-title {
    font-weight: 100;
    line-height: 1.2;
    @include fluid-type($min_width, $max_width, 40px, 80px);
  }
  .iphone-4 {
    max-width: 100%;
    position: relative;
    height: auto;
    margin-left: 10%;
    @include media-breakpoint-down(xs) {
      transform: scale(1.5);
      margin-top: 60px;
      margin-bottom: 60px;
    }
  }
  .custmize-screens {
    .svg {
      max-width: 100%;
    }
  }
}
.download {
  text-align: center;
  h2 {
    margin-bottom: 30px;
    &:after {
      content: "";
      background: #afc2f8;
      height: 7px;
      width: 55px;
      border-radius: 10px;
      display: table;
      margin: 30px auto;
    }
  }
  .app-con {
    max-width: 100%;
    height: auto;
  }
}

.contact-form{
  .form-control-rounded{border-radius:0px 4px 4px 0px !important;}
  .btn[type="submit"]{
    display: table;
    margin: 0px auto;
    min-width: 200px;
  }
}
