footer {
    padding: 80px 0px;
    text-align: center;
    h4{
        @include fluid-type($min_width, $max_width, 18px, 28px);
        font-weight: 200;
        a{
            color: #ff4f4f;
            &:hover{
                color:$primary-color;
            }
        }
    }
}