/*** Bootstrap color variable ***/
$body-bg: #f6fbfa;
$body-color: #000;
$gray-base: #000 !default;
$gray-darker: lighten($gray-base, 13.5%) !default; // #222
$gray-dark: lighten($gray-base, 20%) !default;   // #333
$gray: lighten($gray-base, 33.5%) !default; // #555
$gray-light: lighten($gray-base, 46.7%) !default; // #777
$gray-lighter: lighten($gray-base, 93.5%) !default; // #eee
$theme-colors: (
  "primary": #007bff,
  "secondary": #6c757d
);

/*** link color ***/
$link-color:  $body-color !default;
$link-hover-color: darken($link-color, 15%) !default;
$link-hover-decoration: underline !default;

/*** Container ***/
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
) !default;

/*** Buttons ***/
$btn-font-weight: normal !default;
$btn-border-radius: 4px !default;

/*** Forms Input ***/
$input-bg: #fff !default;
$input-bg-disabled: $gray-lighter !default;
$input-color: $body-color !default;
$input-border-width: 1px;
$input-border-color:$body-color !default;
$input-border-radius: 4px !default;
$input-focus-border-color: $body-color !default; 
$input-focus-color: $body-color !default; 
$input-placeholder-color: $body-color !default;
$input-height: 40px !default;
$input-focus-box-shadow: 0 0 0 0.1rem rgba(10, 10, 10, 0.85) !default;

/*** form-group margin ***/
$form-group-margin-bottom:15px !default;