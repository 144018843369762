header {
    background: rgba($color: #000000, $alpha: 0.9);
}

.nav-link {
    color: $white-color;
    &:hover {
        color: #b2d237;
    }
}
.nav-link.active {
    color: #b2d237;
}